import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { appstoreUrl } from "../constants";
import Seo from "../components/seo";

const Event = ({ location }) => {
  const params = new URLSearchParams(location.search);
  const fromApp = params.get("fromApp") === "yes"

  return (
    <div
      className="vh-100 vw-100 center w-100 tc absolute"
      style={{ backgroundImage: 'linear-gradient(to left, #9114FF, #0e198c)' }}
    >
      <Seo
        title="Twitchcon Celebration | StreamKit"
        description=""
      />
      <section>
        <div className="tc mw6 center ph5 pv4 mt4">
          <div className="f1 white tc b">
            Twitchcon is finally here 🎉
          </div>
          <div className="f3 mt3 white lh-copy tc">And we're celebrating with a Clip Editing contest!</div>
          <div className="f5 mt3 white lh-copy tc">
            Make a clip using the StreamKit app and post it to TikTok and tag{' '}
            <a
              className="white no-underline b"
              href="https://www.tiktok.com/@getstreamkit"
              rel="noreferrer"
              target="_blank"
            >
              @getstreamkit
            </a>{' '}
            to enter for a chance to win $100.
          </div>
          <div className="f5 mv3 white lh-copy tc">On October 24th we'll choose 3 winners. Post before then for a chance to win!</div>
          {!fromApp && (
            <OutboundLink href={appstoreUrl} target="_blank" className="no-underline" rel="noreferrer">
              <div
                className="grow pointer bg-white br3 pa3 flex flex-row items-center justify-center h3"
              >
                <StaticImage
                  backgroundColor="transparent"
                  placeholder="none"
                  height={30}
                  width={30}
                  src="../images/apple-logo-black.png"
                  alt="app store"
                  quality={100}
                />
                <div className="dark-gray b f5 ph2">Download StreamKit!</div>
              </div>
            </OutboundLink>
          )}
          <div className="mt4 f6 gray">US and Canada only. Must be 18 or older to participate. Terms and conditions apply.</div>
        </div>
      </section>
    </div>
  );
}

export default Event;
